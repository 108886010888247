import {defineConfig} from '../config'
import { authHeader } from "../helpers";

import { utilsService } from './utils'
const {handleResponse} = utilsService

export const platformService = {
  getAllPlatforms,
  getPlatformById,
  createPlatform,
  editPlatform,
  changePlatformStatus,
  deletePlatform,
  parseFile,
  updateColumn,
  deleteColumn,
  updateConfig,
  duplicatePlatform,
  getEnums
};

const {apiUrl, apiVer} = defineConfig.PLATFORM_SERVICE

function getAllPlatforms (name) {
  const url = apiUrl+apiVer+"/platform/all?name=" + name;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPlatformById (id) {
  const url = apiUrl+apiVer+"/platform/byId?platformId=" + id ;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createPlatform (body) {
  const url = apiUrl+apiVer+"/platform/create" ;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function editPlatform (body) {
  const url = apiUrl+apiVer+"/platform/update" ;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function changePlatformStatus (id, status) {
  const url = apiUrl+apiVer+"/platform/changeStatus?platformId="+ id + "&platformStatus=" + status  ;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deletePlatform (id) {
  const url = apiUrl+apiVer+"/platform/delete?platformId="+ id  ;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function parseFile (platformId, format, splitter, sheet, formData) {
  const url = apiUrl + apiVer + "/parse/documentToJsonMap?platformId=" + platformId + (format==='CSV' ? ("&splitter=" + splitter ) : ("&sheetIndex=" + sheet));
  const requestOptions = {
    method: "POST",
    headers:{Authorization: authHeader().Authorization},
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateColumn (body, platformId) {
  const url = apiUrl + apiVer + "/platformConfig/updateColumn?platformId=" + platformId;
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteColumn (columnType, platformId) {
  const url = apiUrl + apiVer + "/platformConfig/deleteColumn?columnType="+columnType + "&platformId=" + platformId;
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function duplicatePlatform (platformId) {
  const url = apiUrl + apiVer + "/platform/duplicate?platformId=" + platformId;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateConfig (body, platformId) {
  const url = apiUrl + apiVer + "/platformConfig/updateConfig?platformId=" + platformId;
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getEnums () {
  const url = apiUrl + apiVer + "/platform/enums";
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


import {defineConfig} from '../config'
import { authHeader } from "../helpers";

import { utilsService } from './utils'
const {handleResponse} = utilsService

export const sizeService = {
    getApiPath,
    getSizeValuebytype,
    addSize
};

const {apiUrl, apiVer} = defineConfig.TAGS_SERVICE

function getApiPath(){
  return (apiUrl+apiVer)
}


function getSizeValuebytype(type) {
  const url = apiUrl+apiVer+"/size/findByType?type="+type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function addSize(body) {
  const url = apiUrl+apiVer+"/size/save";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
import { authHeader } from "../helpers";
import { userService } from "."
import {defineConfig} from '../config'

export const companyService = {
  getApiPathCompany,
  createCompany,
  readCompany,
  updateCompany,
  deleteCompany,
  getAllCompanies,
  getAllCompaniesDTO,
  inviteUserByMail,
  validateData,
  handleCompanyStatus,
  getAllCompaniesPaged,
  getCompanyById,
  validateURL,
  sendForm,
  getInfo,
  getActiveCompanies,
  getCompanyBySiteId,
  getAllCompaniesWithSites
};

const {apiUrl, apiVer} = defineConfig.COMPANY_SERVICE

function getApiPathCompany(){
  return (apiUrl+apiVer)
}

function createCompany(company, userId) {
  const url = apiUrl+apiVer+"/company/create?userId=" + userId ;
  const body = JSON.stringify(company)

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function readCompany(id) {
  const url = apiUrl+apiVer+"/company/getById/"+id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateCompany(id, company) {
  const url = apiUrl+apiVer+"/company/update?companyId="+id;

  const body = JSON.stringify(company)

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function deleteCompany(id){
  const url = apiUrl+apiVer+"/company/delete?companyId="+id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {

      switch(response.status){
        case 401:
          // auto logout if 401 response returned from api
          userService.logout();
          window.location.reload(true);
          break;
        case 422:
          return data;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getAllCompanies() {
  const url = apiUrl+apiVer+"/company/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllCompaniesDTO() {
  const url = apiUrl+apiVer+"/company/allDto";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function inviteUserByMail(companyId, email, role) {
  const url = apiUrl+apiVer+"/company/inviteUser?companyId=" + companyId + "&email=" + email + '&role=' + role;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function validateData(form, step) {
  const url = apiUrl+apiVer+"/company/validateData?step=" + step;
  const body = JSON.stringify(form)

  const requestOptions = {
    method: "POST",
    headers: {'content-type': 'application/json'},
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function handleCompanyStatus(id, status) {
  const url = apiUrl+"/company/active?companyId=" + id + '&active=' + status

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  }

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getActiveCompanies() {
  const url = apiUrl + "/company/getActive";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllCompaniesPaged(page, perPage, sortType, sortValue, companyName, name, code, site, email, status) {
  const requestOptions = {
    "method": "GET",
    headers: authHeader("application/json"),
  }
  return fetch(
    apiUrl + '/company/all/page/' + page + "/perPage/" + perPage + "/sort/" + sortType + '?sortValue=' + sortValue + "&companyName=" + companyName + "&userFullName=" + name + '&code=' + code + '&siteName=' + site + '&email=' + email + '&status=' + status ,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getCompanyById(id) {
  const requestOptions = {
    "method": "GET",
    headers: authHeader("application/json"),
  }
  return fetch(
    apiUrl + "/company/getCompanyById/" + id,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function validateURL(site) {
  const url = apiUrl+apiVer+"/company/validateSite?siteName=" + site;

  const requestOptions = {
    method: "POST",
    headers: {'content-type': 'application/json'},
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
      return res;
    });
}

function sendForm(form, token) {
  const url =  apiUrl + apiVer + "/company/onboard?token=" + token;
  const body = JSON.stringify(form)

  const requestOptions = {
    method: "POST",
    headers: {'content-type': 'application/json'},
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getInfo(token, mail) {
  const url = apiUrl+apiVer+"/company/getVatByOnboardToken?token=" + token+"&email=" + mail;

  const requestOptions = {
    method: "GET",
    headers: {'content-type': 'application/json'},
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getCompanyBySiteId(siteId) {
  const url = apiUrl+apiVer+"/company/getCompanyBySiteId?siteId=" + siteId;

  const requestOptions = {
    method: "GET",
    headers: {'content-type': 'application/json'},
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllCompaniesWithSites() {
  const url = apiUrl+apiVer+"/company/allWithSites";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


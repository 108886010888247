export const gatewayConfig = {
  INSIGHT_SERVICE,
  INVOICE_SERVICE,
  TAGS_SERVICE,
  SITES_SERVICE,
  PUBLISHERS_SERVICE,
  FORMATS_SERVICE,
  CAMPAIGN_SERVICE,
  COMPANY_SERVICE,
  PLATFORM_SERVICE,
  METRICS_SERVICE,
  USER_SERVICE,
  QUOTA_SERVICE,
  ADMANAGER_SERVICE,
  NOTIFICATION_SERVICE,
  CONTEXTUAL_SERVICE
}


function INSIGHT_SERVICE(ambience) {
  let apiUrl, apiVer
  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function INVOICE_SERVICE(ambience) {
  let apiUrl, apiVer
  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function TAGS_SERVICE(ambience) {
  let apiUrl, apiVer
  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}


function SITES_SERVICE(ambience) {
  let apiUrl, apiVer
  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function PUBLISHERS_SERVICE(ambience) {
  let apiUrl, apiVer
  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}


function FORMATS_SERVICE(ambience) {
  let apiUrl, apiVer
  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}


function CAMPAIGN_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function COMPANY_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev"
      apiVer = ""
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it"
      apiVer = ""
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it"
      apiVer = ""
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function USER_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev"
      apiVer = ""
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it"
      apiVer = ""
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it"
      apiVer = ""
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function PLATFORM_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function METRICS_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function QUOTA_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function ADMANAGER_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/adgateway/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/adgateway/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/adgateway/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function NOTIFICATION_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}

function CONTEXTUAL_SERVICE(ambience) {
  let apiUrl, apiVer

  switch(ambience){
    case "dev":
      apiUrl = "https://api.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://analytics-api-stage.movingup.it/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://analytics-api.movingup.it/"
      apiVer = "v1"
      break;
    default:
      return null;
  }

  return {apiUrl, apiVer}
}


import { authHeader } from "../helpers";
// import { userService } from "./"
// import FileSaver from 'file-saver';
import { result } from 'lodash';
import {defineConfig} from '../config'
import { utilsService } from './utils'
const {handleResponse} = utilsService

export const sitesService = {
    getApiPath,
    getSingleSite,
    getAllSite,
    getAllSitesPaginated,
    addSite,
    editSite,
    disableSite,
    getSitesFromNetwork,
    addSiteToNetwork,
    removeSiteToNetwork,
    publishSellerJson,
    getAllSitesToManage,
    editSiteStatus,
    getCreatedSites,
    getAllSitesByCompany,
    getAllSitesByCompanyPaged,
    getSiteStatuses,
    getAllbusinessUnits,
    createBusinessUnit,
    editBusinessUnit,
    getKickAss,
    getMatrixAudio,
    getBustAMove,
};

const {apiUrl, apiVer} = defineConfig.SITES_SERVICE

function getApiPath(){
  return (apiUrl+apiVer)
}

function getAllSite() {
  const url = apiUrl+apiVer+"/site/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllSitesPaginated(page, perPage) {
  const url = apiUrl+apiVer+"/site/all/page/"+ page+"/perPage/"+ perPage + "/sort/asc" ;
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;

    });
}

function getSingleSite(id) {
  const url = apiUrl+apiVer+"/site" + `?sid=${id}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function addSite(data, idCompany, businessUnits) {
  const url = apiUrl+apiVer+"/site/create/?idCompany=" + idCompany + "&businessUnitsIds=" + businessUnits;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "authorization": authHeader("application/json").Authorization
    },
    body: JSON.stringify(
      {
        ...data,
        "status":"CREATED"
      }
    ),
    
  };
  
  return fetch(
    url,
    requestOptions
  )
  .then (response => response.json())
}

function editSite (data, idCompany, businessUnits) {

  const url = apiUrl+apiVer+"/site/update?idCompany=" + idCompany + "&businessUnitsIds=" + businessUnits;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(data),
  };
  
  return fetch(
    url,
    requestOptions
  )
  .then (response => response.json())
}

function disableSite (id){

  const url = apiUrl+apiVer+"/site/delete"+`/?siteId=${id}`;

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "authorization": authHeader("application/json").Authorization
    },
  };
  
  return fetch(
    url,
    requestOptions
  )
}


function getSitesFromNetwork () {
  const url = apiUrl+apiVer+"/site/network";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  }
  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function addSiteToNetwork(data) {

  const url = apiUrl+apiVer+"/site/addSitesToNetwork";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(data),
  };
  
  return fetch(
    url,
    requestOptions
  )
  .then (response => response.json())
}

function removeSiteToNetwork(data) {

  const url = apiUrl+apiVer+"/site/removeSitesToNetwork";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(data),
  };
  
  return fetch(
    url,
    requestOptions
  )
  .then (response => response.json())
}


function publishSellerJson() {

  const url = apiUrl+apiVer+"/site/publishNetwork";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };
  
  return fetch(
    url,
    requestOptions
  )
  .then (response => response.json())
}

function getAllSitesToManage(page, perPage) {
  const url = apiUrl+apiVer+"/site/disactive/page/"+ page+"/perPage/"+ perPage + "/sort/asc" ;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;

    });
}

function getCreatedSites(page, perPage) {
  const url = apiUrl+apiVer+"/site/created/page/"+ page+"/perPage/"+ perPage + "/sort/asc" ;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;

    });
}

function editSiteStatus(idSite, value, userId, userMessage ) {
  const url = apiUrl+apiVer+"/site/changeSiteStatus" + `?idSite=${idSite}&status=${value}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      'userId': userId,
      'userMessage': userMessage,
      "authorization": authHeader("application/json").Authorization
    },
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;

    });
}

function getAllSitesByCompany(companyId, name) {
  const url = apiUrl+apiVer+"/site/allByCompanyId/?companyId=" + companyId + '&siteName=' + name;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;

    });
}

function getAllSitesByCompanyPaged(companyId, name, companyName, searchStatus, searchAnalytics, businessUnit, page, perPage, sortType, sortValue) {
  const url = apiUrl+apiVer+"/site/allByCompanyIdPaged/page/" + page +"/perPage/" + perPage + '/sort/' + sortValue + '?sortValue=' + sortType + "&companyId=" + companyId + '&siteName=' + name + '&companyName='+ companyName + "&status=" + searchStatus + '&analytics='  + searchAnalytics + '&businessUnit=' + businessUnit;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;

    });
}

function getSiteStatuses() {
  const url = apiUrl+apiVer+"/site/getSiteStatus";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllbusinessUnits() {
  const url = apiUrl+apiVer+"/businessUnits/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createBusinessUnit(name) {
  const url = apiUrl+apiVer+"/businessUnits/create?name="+name;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function editBusinessUnit(id, name) {
  const url = apiUrl+apiVer+"/businessUnits/update?id=" + id +"&name=" + name;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getKickAss() {
  const url = apiUrl + apiVer + "/easteregg/kickAss.js";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(response => response.text());
}

function getBustAMove() {
  const url = apiUrl + apiVer + "/easteregg/bust_move.html";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(response => response.text());
}

function getMatrixAudio() {
  const url = apiUrl + apiVer + "/easteregg/matrix.mp3"; 
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      return URL.createObjectURL(blob);
    });
}
import React, {Suspense, useEffect} from 'react'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import {defineConfig} from './config'
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute.react";
import Notification from './components/Notification/Notification';
import { NotificationsProvider } from 'reapop';
import Loader from './components/Loader/Loader.react'
import 'ant-design-pro/dist/ant-design-pro.min.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import "tabler-react/dist/Tabler.css";
import './styles/App.scss';
// import './styles/sour-theme.scss';
import {Button, Error404Page, Error403Page} from 'tabler-react'
import {userService} from './services'
import { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { setUser } from './redux/actions'
import { themes } from './styles/themes';
import { ConfigProvider } from 'antd';
import store from './redux/store';

const LogoutButton = <>
  <Button color='secondary' size="lg" className='mb-6' onClick={() => window.location.replace("/logout")}>LOGOUT</Button>
  <br/>
  <strong>OR</strong>
</>

const Error403 = (props) => <Error403Page title={<h1 className="display-2 mt-6">403</h1>} details={LogoutButton} {...props} />
const Error404 = (props) => <Error404Page title={<h1 className="display-2 mt-6">404</h1>} {...props} />

const {routes, ADMIN_ROLE} = defineConfig

const getPageLazy = (path) => React.lazy(() => {
  return Promise.all([
    import(`${path}`),
    new Promise(resolve => setTimeout(resolve, 100))
  ])
  .then(([moduleExports]) => moduleExports);
});


const publicRoutes = [
  {
    path: "/login",
    component: "./pages/Login/Login.react"
  },
  {
    path: "/external-login",
    component: "./pages/Login/ExternalLogin"
  },
  {
    path: "/logout",
    component: "./pages/Login/Login.react"
  },
  {
    path: "/signup",
    component: "./pages/Signup/Signup.react"
  },
  {
    path: "/signup/validate-email",
    component: "./pages/Signup/SignupValidateEmail.react"
  },
  {
    path: '/signup/onboarding',
    component: './pages/Signup/Configuration.Wizard'
  },
  {
    path: '/forgotpassword',
    component: './pages/Login/ForgotPassword'
  },
  {
    path: '/changepassword',
    component: './pages/Login/ChangePassword'
  },

]

const publicPages = publicRoutes.map(route => {
  return {
    path: route.path,
    Render: getPageLazy(route.component)
  }
})

function App() {
  const [type, setType] = useState(userService.getLocalUserType());
  const dispatch = useDispatch();
  const [isStorageLoading, setIsStorageLoading] = useState(false)
  const [privatePages, setPrivatePages] = useState([])

  useEffect( () => {
    console.log("\n   ___          _                            \n  / __\\___   __| | ___  ___  ___  _   _ _ __ \n / /  / _ \\ / _` |/ _ \\/ __|/ _ \\| | | | '__|\n/ /__| (_) | (_| |  __/\\__ \\ (_) | |_| | |   \n\\____/\\___/ \\__,_|\\___||___/\\___/ \\__,_|_|   ")
    setIsStorageLoading(true)
    const user = store.getState().user
    if(!user){
      const auth = localStorage.getItem("auth")
      userService.profile(auth).then(
        user => {
          dispatch(setUser(user));
          userService.setInStorage(user)
          setIsStorageLoading(false)
        },
        error => {
          console.log(error)
          setIsStorageLoading(false)
        }
      );
    }
    setType(userService.getLocalUserType())

    const routesWithPermission = routes.map(route => {
      if((type === ADMIN_ROLE) || (route.roles && route.roles.find(role => role === type))){
        route.isAllowed = true;
      }
      else{
        route.isAllowed = false;
      }
      return route;
    })

    var tempPages = routesWithPermission.map(route => {
      return {
        path: route.path,
        isAllowed: route.isAllowed,
        Render: getPageLazy(route.component)
      }
    })
    tempPages = setDefaultHomePage(tempPages);
    setPrivatePages(tempPages)
  }, [dispatch])

  const setDefaultHomePage = (privPage) => {
    // Si presuppone che ci sia una sola homepage
    const homePage = privPage.find(pp => pp.path === '/')
    if(!homePage.isAllowed) {
      let homeFake = privPage.filter(pp => pp.isAllowed)[0]
      if(!homeFake)
        return privPage

      homePage.Render = homeFake.Render
      homePage.isAllowed = true
      privPage = privPage.map(pp => {
        if(pp.path === '/'){
          pp = homePage
        }
        return pp
      })
    }
    return privPage
  }

  return (
    <NotificationsProvider>
      <Notification>
      </Notification>
      <ConfigProvider theme={themes.movingUp}>
        <Suspense fallback={<Loader fullscreen />}>
          <Router>
            <Routes>
              {isStorageLoading ? <Route component={<Loader fullscreen></Loader>}/> : privatePages.length>0 && privatePages.map((Component, index) => ( 
                <Route key={index} path={Component.path} element={<PrivateRoute isAllowed={Component.isAllowed} component={Component.Render} />} />
              ))}
              {publicPages.map((Component, index) => (
                <Route key={index} path={Component.path} element={<Component.Render />} />
              ))}
              <Route key="Error-403" exact path={"/403"} render={Error403}/>
                <Route key="Error-404" exact path={"/404"} render={Error404}/>
                <Route component={Error404}/>
            </Routes>
          </Router>
        </Suspense>
      </ConfigProvider>
    </NotificationsProvider>
  );
}

export default App;
import {defineConfig} from '../config'
import { authHeader } from "../helpers";

import { utilsService } from './utils'
const {handleResponse} = utilsService

export const adsTxtService = {
  getApiPath,
  getAdsTxtLastByPlanningId,
  getAdsTxtOnCdnByPlanningId,
  saveAdsTxt,
  GetAdsTxtUserConfig,
  UpdateAdsTxtUserConfig,
  getAdsTxtStatus
};

const {apiUrl, apiVer} = defineConfig.TAGS_SERVICE

function getApiPath(){
  return (apiUrl+apiVer)
}

function getAdsTxtLastByPlanningId(planningId) {
  const url = apiUrl+apiVer+"/adsTxt/planning/lastVersion?pid=" + planningId;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAdsTxtOnCdnByPlanningId(planningId) {
  const url = apiUrl+apiVer+"/adsTxt/planning/published?pid=" + planningId;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function saveAdsTxt(planningId, adsTxtValue, message, publish = false) {
  const url = apiUrl+apiVer+"/adsTxt/save?pid=" + planningId + "&publish=" + publish;
  
  const bodyString = JSON.stringify({
    value: adsTxtValue,
    description: message
  })

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: bodyString
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function GetAdsTxtUserConfig() {
  const url = apiUrl+apiVer+"/adsTxt/getAdsTxtUser";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function UpdateAdsTxtUserConfig(main, others) {
  const url = apiUrl+apiVer+"/adsTxt/updateAdsTxtUser";
  
  const body = {
    to: main,
    otherUsers: others
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAdsTxtStatus(plannerId) {
  const url = apiUrl+apiVer+"/adsTxt/adsTxtCheck?plannerId=" + plannerId;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
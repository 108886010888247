import {defineConfig} from '../config'
import { authHeader } from '../helpers';
import { utilsService } from './utils'
const {handleResponse} = utilsService

export const metricsService = {
    getApiPath,
    getAllMetrics,
    createMetric,
    deleteMetric,
    getMetricById,
    updateMetric,
    syncMetrics
};

const {apiUrl, apiVer} = defineConfig.METRICS_SERVICE

function getApiPath(){
  return (apiUrl+apiVer)
}

function getAllMetrics(dateString) {
  let url = apiUrl+apiVer+"/metrics/all";

  if(dateString !== null){
    url += ("?date=" + dateString);
  }

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getMetricById(id) {
  const url = apiUrl + apiVer +"/metrics/"+ id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateMetric(id, metricTemplate) {
  const url = apiUrl+apiVer+"/metrics/update/" + id ;
  
  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(metricTemplate)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createMetric(metricTemplate) {
  const url = apiUrl+apiVer+"/metrics/create";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(metricTemplate)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteMetric(metricId) {
  const url = apiUrl+apiVer+"/metrics/delete/" + metricId;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function syncMetrics(dateString) {
    const url = apiUrl+apiVer+"/metrics/sync/pageviews/serviceAccount?date=" + dateString;
  
    const requestOptions = {
      method: "POST",
      headers: authHeader("application/json"),
    //   body: JSON.stringify(metricTemplate)
    };
  
    return fetch(
      url,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
import {defineConfig} from '../config'
import { authHeader } from "../helpers";

import { utilsService } from './utils'
const {handleResponse} = utilsService

export const quotaService = {
    getApiPath,
    getDefault,
    getBySiteId,
    checkDates,
    createQuota
};

const {apiUrl, apiVer} = defineConfig.QUOTA_SERVICE

function getApiPath(){
  return (apiUrl+apiVer)
}

function getDefault() {
  const url = apiUrl+apiVer+"/quote/getAllDefault";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getBySiteId(idSite) {
  const url = apiUrl+apiVer+"/quote/findBySite?idSite="+idSite;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function checkDates(body) {
  const url = apiUrl+apiVer+"/quote/check";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createQuota(body) {
  const url = apiUrl+apiVer+"/quote/create";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

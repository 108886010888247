import React from 'react';
import Lottie from "lottie-react";
import TextLoop from "react-text-loop";
import animationData from './codesour-loader.json'

function Loader(props) {
    const { fullscreen, simpleLoader, messages, className } = props
    if (fullscreen) {
        return (
            <div className={"row " + className} style={{ background: "#fff" }}>
                <div className="col text-center w-100 vh-100">
                    <div style={{ width: 200, borderRadius: "50%", margin: "auto", marginTop: "20vh", background: "#ffffff" }}>
                        <Lottie animationData={animationData} />
                    </div>
                </div>
            </div>
        )
    } else if (simpleLoader) {
        return (
            <div className={"row " + className} style={{display: 'flex', justifyContent: 'center'}}>
                <div className="col-auto">
                    <div className="text-center" style={{ width: "150px", borderRadius: "50%", background: "#ffffff" }}>
                        <Lottie animationData={animationData} />
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={"row " + className}>
                <div className="col-auto mx-auto ">
                    <div className="text-right" style={{ width: "150px", borderRadius: "50%", background: "#ffffff" }}>
                        <Lottie animationData={animationData} />
                    </div>
                </div>
                {messages &&
                    <div className="col align-self-center mt-4">
                        <TextLoop>
                            {messages.map(message => <h3 className="text-poppins">{message}</h3>)}
                        </TextLoop>
                    </div>
                }
            </div>
        )
    }
}

export default Loader;
import { defineConfig } from '../config'
import FileSaver from 'file-saver';
import { authHeader } from "../helpers";

import { utilsService } from './utils'
const { handleResponse, handleResponseBlob } = utilsService

export const tagsService = {
  getApiPath,
  downloadConfig,
  getListUnitByPlannerId,
  getListBidsByPlannerId,
  getTotalsUnitsByPlannerId,
  getFormatActiveByPlannerId,
  getPlayerActiveByPlannerId,
  getScriptToLoadActiveByPlannerId,
  getTotalsNativeByPlannerId,
  getListNativeByPlannerId,
  getTrackerActiveByPlannerId,
  getAllPlanning,
  getPlanningsByIdSite,
  getUrlExportPlanning,
  getConfigJsExportPlanning,
  getPlanning,
  initPlanning,
  updatePlanning,
  rewritePlanning,
  savePlanningAdUnit,
  savePlanningNative,
  savePlanningThirdParts,
  savePlanningDmp,
  savePlanningPrebid,
  savePlanningOptical,
  savePlanningPlayer,
  savePlanningIntext,
  savePlanningInterstitial,
  saveLinkFallback,
  publishPlanning,
  deletePlanningAdUnit,
  deletePlanningNative,
  getUrlImportPlanning,
  previewSeller,
  getSeller,
  updateSeller,
  syncSeller,
  saveTrackerConfiguration,
  getAllPlanningSiteDto,
  publishAllPlanning,
  getAllMcmValues,
  saveMcmValues,
  editMCM,
  deleteMCM,
  verifyGlobalRule,
  confirmRule,
  getHistory,
};

const { apiUrl, apiVer } = defineConfig.TAGS_SERVICE

function getApiPath() {
  return (apiUrl + apiVer)
}

function downloadConfig(filename, json) {
  var file = new Blob([json], { type: "application/json" });
  FileSaver.saveAs(file);
}

function getPlanning(planningId) {
  const url = apiUrl + apiVer + "/planning/current?planningId=" + planningId;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllPlanning(page, perPage, query = "") {
  const url = apiUrl + apiVer + "/planning/all/?page=" + page + "&perpage=" + perPage + "&query=" + query.toString().trim();

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllMcmValues() {
  const url = apiUrl + apiVer + "/mcm/getAll"

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function saveMcmValues(mcmValue, type) {
  const url = apiUrl + apiVer + "/mcm/create?mcmValue=" + mcmValue + "&type=" + type

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function editMCM(id, mcmValue, type) {
  const url = apiUrl + apiVer + "/mcm/edit?id=" + id + "&mcmValue=" + mcmValue + "&type=" + type

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteMCM(id, type) {
  const url = apiUrl + apiVer + "/mcm/delete?id=" + id + "&type=" + type

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPlanningsByIdSite(idSite) {
  const url = apiUrl + apiVer + "/planning/byIdSite?idSite=" + idSite;
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllPlanningSiteDto() {
  const url = apiUrl + apiVer + "/planning/allPlanningSiteDto";
  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function publishAllPlanning(planningIdsList, ambience) {
  const url = apiUrl + apiVer + "/planning/publishAll?environment=" + ambience;
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(planningIdsList)
  };
  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getUrlExportPlanning(id) {
  const url = apiUrl + apiVer + "/planning/xls/export/plannId/" + id;
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponseBlob)
    .then(res => {
      console.log("result", res)
      return res;
    });

}

function getConfigJsExportPlanning(id) {
  const url = apiUrl + apiVer + "/planning/configDownload/?planningId=" + id;

  const requestOptions = {
    method: "GET",
    headers:authHeader(),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponseBlob)
    .then(res => {
      
      return FileSaver.saveAs(res, 'config.js');
    });
}

function publishPlanning(environment, planningId) {
  const url = apiUrl + apiVer + "/planning/publish/?environment=" + environment + "&planningId=" + planningId;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getUrlImportPlanning(planningId) {
  return {
    action: apiUrl + apiVer + "/fileparse/upload/" + planningId,
    headers: authHeader(),
  };
}

function initPlanning(planning) {
  const url = apiUrl + apiVer + "/planning/init";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(planning)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updatePlanning(planning) {
  const url = apiUrl + apiVer + "/planning/updatePlan";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(planning)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function rewritePlanning(planning) {
  const url = apiUrl + apiVer + "/planning/clone";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(planning)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningAdUnit(planningId, adUnit) {
  const url = apiUrl + apiVer + "/planning/saveAdUnit/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(adUnit)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningNative(planningId, nativeUnit) {
  const url = apiUrl + apiVer + "/planning/saveNative/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(nativeUnit)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningThirdParts(planningId, thirdParts) {
  const url = apiUrl + apiVer + "/planning/saveThird/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(thirdParts)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningDmp(planningId, dmp) {
  const url = apiUrl + apiVer + "/planning/saveDmp/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(dmp)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningPlayer(planningId, player) {
  const url = apiUrl + apiVer + "/planning/savePlayer/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(player)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningOptical(planningId, optical) {
  const url = apiUrl + apiVer + "/planning/saveOptical/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(optical)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningPrebid(planningId, pbjs) {
  const url = apiUrl + apiVer + "/planning/savePbjs/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(pbjs)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function saveLinkFallback(planningId, parentId, childrenId) {
  const url = apiUrl + apiVer + "/planning/linkFallBack/?planningId=" + planningId + "&parentId=" + parentId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(childrenId)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningIntext(planningId, intextConfig) {
  const url = apiUrl + apiVer + "/planning/saveIntext/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(intextConfig)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePlanningInterstitial(planningId, intextConfig) {
  const url = apiUrl + apiVer + "/planning/saveInterstitial/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(intextConfig)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deletePlanningAdUnit(planningId, adUnitId) {
  const url = apiUrl + apiVer + "/planning/deleteAdUnit/?planningId=" + planningId + "&adUnitId=" + adUnitId;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
    // body: JSON.stringify(adUnit)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deletePlanningNative(planningId, nativeId) {
  const url = apiUrl + apiVer + "/planning/deleteNatives/?planningId=" + planningId + "&nativeId=" + nativeId;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
    // body: JSON.stringify(adUnit)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function previewSeller() {
  const url = apiUrl + apiVer + "/seller/preview";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getSeller() {
  const url = apiUrl + apiVer + "/seller/get";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateSeller(json) {
  const url = apiUrl + apiVer + "/seller/edit";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(json)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function syncSeller() {
  const url = apiUrl + apiVer + "/seller/syncFromProduction";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function saveTrackerConfiguration(planningId, trackerConfiguration) {
  const url = apiUrl + apiVer + "/planning/saveTracker/?planningId=" + planningId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(trackerConfiguration)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getListUnitByPlannerId(id) {
  const url = apiUrl + apiVer + "/planning/adunits/list/" + id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getListBidsByPlannerId(id) {
  const url = apiUrl + apiVer + "/planning/adunits/bids/" + id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTotalsNativeByPlannerId(id) {
  const url = apiUrl + apiVer + "/planning/natives/totals/" + id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getListNativeByPlannerId(id) {
  const url = apiUrl + apiVer + "/planning/natives/list/" + id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTotalsUnitsByPlannerId(id) {
  const url = apiUrl + apiVer + "/planning/adunits/totals/" + id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getFormatActiveByPlannerId(id, name) {
  const url = apiUrl + apiVer + "/planning/adunits/" + name + "/" + id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getScriptToLoadActiveByPlannerId(id, name) {
  const url = apiUrl + apiVer + "/planning/scriptToLoad/getByName/" + id + "?scriptName=" + name;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPlayerActiveByPlannerId(id, name) {
  const url = apiUrl + apiVer + "/planning/players/getByName/" + id + "?playerName=" + name;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTrackerActiveByPlannerId(id) {
  const url = apiUrl + apiVer + "/planning/tracker/list/" + id;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Authorization": authHeader("application/json").Authorization
    }
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function verifyGlobalRule(device, sizes) {
  const url = apiUrl + apiVer + "/planning/getAdUnitsByRules?device=" + device + "&sizes=" + sizes ;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function confirmRule(device, sizes, body) {
  const url = apiUrl + apiVer + "/planning/saveAdUnitsByRules?device=" + device + "&sizes=" + sizes ;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getHistory(planningId) {
  const url = apiUrl + apiVer + "/planning/getHistory?planningId=" + planningId;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
import React, { useEffect, useState, useRef } from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import NotificationsSystem, { atalhoTheme, setUpNotifications, useNotifications } from 'reapop'
import { Card, Button } from 'antd';
import { CalendarOutlined, CarryOutOutlined, CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, ExclamationCircleOutlined, NotificationOutlined } from '@ant-design/icons'
import NotificationSound from '../../utils/notification.mp3';


const WebSocketComponent = () => {
  const [messages, setMessages] = useState([]);
  const { notifications, dismissNotification, notify } = useNotifications()
  
  useEffect(()=>{
    setUpNotifications({
      defaultProps: {
        position: 'top-right',
        dismissible: true,
        dismissAfter: 5000
      } 
    })
  },[])

  const handleIncomingMessage = (message, id) => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(message.userId === user.principal.id || !message.userId){
      notify({
        id: id,
        title: message.title,
        message: message.message,
        status: message.status,
        action: message.action
      });
      playAudio()
    }
  };

  const audioPlayer = useRef(null);

  const playAudio = () => {
    audioPlayer.current.play();
  }

  const actionLink = (action) => {
    switch(action.type){
      case 'contextual':
        return '/sites?id=' + action.payload;
      case 'invoice':
        return '/invoiceManager?startDate=' + action.payload.startDate + '&endDate=' + action.payload.endDate;
    }
  }

  const NotificationCard = ({ notification }) => {
    const { title, message, status, id } = notification

    const handleIcon = () => {
      switch(status){
        case 'SUCCESS':
          return <CheckCircleOutlined className='d-flex align-items-center' style={{color: '#52C41A', fontSize:'32px'}}/>;
        case 'INFO':
          return <ExclamationCircleOutlined className='d-flex align-items-center' style={{color: '#FAAD14', fontSize:'32px'}}/>;
        case 'ERROR':
          return <CloseCircleOutlined className='d-flex align-items-center' style={{color: '#FF4D4F', fontSize:'32px'}}/>;
        case 'GENERAL':
          return <NotificationOutlined className='d-flex align-items-center' style={{fontSize:'32px'}}/>;
        case 'REMINDER':
          return <CalendarOutlined className='d-flex align-items-center' style={{fontSize:'32px'}}/>;
        case 'TASK':
          return <CarryOutOutlined className='d-flex align-items-center' style={{fontSize:'32px'}}/>;
        default:
          break;
      }
    }

    return (
      <Card className='my-3' title={null} style={{width:'400px',boxShadow:'0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #0000000d'}}>
        <div className="row">
          <div className="col-2 d-flex">
            {handleIcon()}
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col">
                <h5>{title}</h5>
              </div>
              <div className="col-auto">
                <CloseOutlined onClick={()=>dismissNotification(id)}></CloseOutlined>
              </div>
            </div>
            <p>{message}</p>
          </div>
        </div>
        {notification.action && 
          <div className="row justify-content-end">
            <div className="col-auto">
              <Button type='primary' size='small' onClick={()=>window.location.assign(actionLink(notification.action))}>Go to page</Button>
            </div>
          </div>
        }
      </Card>
    );
  }
  
  const socket = new SockJS('https://notification-service.movingup.it/v1/notification/ws-message');
  const stompClient = Stomp.over(socket);
  useEffect(() => {
    stompClient.connect({}, () => {
      console.log('Connected to WebSocket server');
      stompClient.subscribe('/topic/user-contextual-notification', (message) => {
        setTimeout(()=>{
          handleIncomingMessage(JSON.parse(message.body), message.headers["message-id"])
        }, 100)
      });
      stompClient.subscribe('/topic/messages', (message) => {
        setTimeout(()=>{
          handleIncomingMessage(JSON.parse(message.body), message.headers["message-id"])
        }, 100)
      });
    });

    return () => {
      stompClient.disconnect();
    };
  }, []);

  return (
    <>
      <NotificationsSystem
        notifications={notifications}
        theme={atalhoTheme}
        dismissNotification={dismissNotification}
        components={{
          Notification: NotificationCard,
        }}
      />
      <audio src={NotificationSound} ref={audioPlayer} />
    </>
  );
};

export default WebSocketComponent;
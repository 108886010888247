import { authHeader } from "../helpers";
import { defineConfig } from '../config'

export const userService = {
  login,
  refreshLogin,
  profile,
  checkProfile,
  logout,
  setInStorage,
  registerUser,
  registerUserVerify,
  handleUserStatus,
  createUser,
  updateUser,
  deleteUser,
  getTaxonomyAll,
  getConfigByTypeUser,
  getColorByType,
  getLabelByType,
  getLocalUserType,
  forgotPassword,
  changePassword,
  savePassword,
  resendOtpCode,
  getAllUsers,
  uploadProfileImg,
  getProfileImgById,
  getUserById,
  getAllRoles,
  getByRole,
  getAllNotOwnerUser,
  changeUserCompany,
  getLocalUserAcceptedInfo,
  acceptTerms,
  getAllAdmins
};

const { apiUrl, apiVer } = defineConfig.USER_SERVICE

function login(username, password) {
  let formData = new FormData()
  formData.append('username', username)
  formData.append('password', password)
  formData.append('grant_type', 'password')

  const requestOptions = {
    "method": "POST",
    "headers": {
      "Authorization": "Basic " + window.btoa('browser:1234')
    },
    body: formData
  }

  return fetch(
    apiUrl + apiVer + '/oauth/token',
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      // login successful if there's a user in the response
      if (response) {
        console.log(response)
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        const auth = response.token_type + " " + response.access_token
        const refresh = response.refresh_token
        localStorage.setItem("auth", auth);
        localStorage.setItem("refresh", refresh);
        return profile(auth).then(
          user => {
            const resUser = this.setInStorage(user)
            return resUser
          },
          error => {
            console.log(error);
            return error;
          }
        );
      }


    })
}

function refreshLogin(refreshToken) {
  let formData = new FormData()
  formData.append('refresh_token', refreshToken)
  formData.append('grant_type', 'refresh_token')

  const requestOptions = {
    "method": "POST",
    "headers": {
      "Authorization": "Basic " + window.btoa('browser:1234')
    },
    body: formData
  }

  return fetch(
    apiUrl + apiVer + '/oauth/token',
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      // login successful if there's a user in the response
      if (response) {
        console.log(response)
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        const auth = response.token_type + " " + response.access_token
        const refresh = response.refresh_token
        localStorage.setItem("auth", auth);
        localStorage.setItem("refresh", refresh);
        return profile(auth).then(
          user => {
            const resUser = this.setInStorage(user)
            return resUser
          },
          error => {
            console.log(error);
            return error;
          }
        );
      }


    })
}

function setInStorage(user) {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("type", user.authorities && user.authorities[0]);
  localStorage.setItem("group", user.group);
  localStorage.setItem("check", 1);
  return user
}

function profile(auth) {
  let header = {
    'Authorization': auth
  }
  const requestOptions = {
    "method": "GET",
    "headers": header
  };

  return fetch(
    apiUrl + apiVer + '/user/profile',
    requestOptions
  ).then(handleResponse);
}

function checkProfile() {
  const auth = localStorage.getItem("auth");

  userService.profile(auth).then(
    user => {
      // console.log('Check profile', user);
    },
    error => {
      console.log(error);
      window.location.replace("/logout?status=session_expired");
    }
  );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("type");
  localStorage.removeItem("group");
  localStorage.removeItem("auth");
  localStorage.removeItem("currentPublisher");
}



function registerUser(user) {
  const url = apiUrl + apiVer + "/prereg/signup";
  const body = JSON.stringify(user)

  const requestOptions = {
    method: "POST",
    headers: {'content-type': 'application/json'},
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function registerUserVerify(token, code) {
  const url = apiUrl + apiVer + `/prereg/verify?token=${token}&code=${code}`;

  const requestOptions = {
    method: "GET",
    headers: {'content-type': 'application/json'},
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createUser(user) {
  const url = apiUrl + apiVer + "/user/createUser";
  const body = JSON.stringify(user)

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function readUserAll() {
  const url = apiUrl + apiVer + "/user/get/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteUser(id) {
  const url = apiUrl + "/user/deleteUser/?userId=" + id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTaxonomyAll() {
  const url = apiUrl + apiVer + `/prereg/taxonomy/all`;

  const requestOptions = {
    method: "GET",
    headers: {'content-type': 'application/json'},
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getTypesList() {
  return [
    {
      label: "Super Admin",
      value: "ROLE_ADMIN",
      desc: "All permission",
      color: "#f39200"
    },
    {
      label: "Manager",
      value: "ROLE_MANAGER",
      desc: "Manage all company user",
      color: "orange"
    },
    {
      label: "Finance User",
      value: "ROLE_FINANCE",
      desc: "Users who manage finance resource",
      color: "purple"
    },
    {
      label: "Team Leader",
      value: "ROLE_TL",
      desc: "Admin only for own team",
      color: "yellow"
    },
    {
      label: "Operation",
      value: "ROLE_OP",
      desc: "Simple Ad Operator",
      color: "lime"
    },
    {
      label: "Publisher",
      value: "ROLE_PUBLISHER",
      desc: "Publisher rules",
      color: "green",
      sidebar: true
    },
    {
      label: "Guest User",
      value: "ROLE_GUEST",
      desc: "Publisher rules",
      color: "cyan",
      sidebar: true
    },
    // {
    //   label: "Advertiser",
    //   value: "advertiser",
    //   desc: "Advertiser rules",
    //   color: "green"
    // },
    // {
    //   label: "Media Owner",
    //   value: "mediaowner",
    //   desc: "Media Owner rules",
    //   color: "teal"
    // }
  ]
}

function getColorByType(type) {
  const types = getTypesList()
  let objType = types.filter(t => type === t.value)
  const color = objType.length === 1 ? objType[0].color : null
  return color
}

function getLabelByType(type) {
  const types = getTypesList()
  let objType = types.filter(t => type === t.value)
  const label = objType.length === 1 ? objType[0].label : null
  return label
}

function getLocalUserType() {
  return localStorage.getItem("type") || null;
}

function getLocalUserAcceptedInfo() {
  const userInfo = {
    "acceptedMarketing": JSON.parse(localStorage.getItem("user")).principal.acceptedMarketing,
    "acceptedTerms": JSON.parse(localStorage.getItem("user")).principal.acceptedTerms,
}
  return userInfo || null;
}

function acceptTerms(userId, marketing, terms) {
  const requestOptions = {
    "method": "POST",
    headers: authHeader("application/json"),
  }
  
  return fetch(
    apiUrl + '/user/setAcceptedTerms/?userId=' + userId + "&acceptedMarketing=" + marketing + "&acceptedTerms=" + terms,
    requestOptions
  )

    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getConfigByTypeUser() {
  const type = getLocalUserType();
  const typesList = getTypesList();
  const objType = typesList.filter(t => type === t.value)
  return objType;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function forgotPassword(username) {
  const requestOptions = {
    "method": "POST",
    headers: {'content-type': 'application/json'},
  }
  return fetch(
    apiUrl + '/user/forgotPassword?username=' + username,
    requestOptions
  )

    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function changePassword(token) {
  const requestOptions = {
    "method": "GET",
    headers: {'content-type': 'application/json'},
  }
  return fetch(
    apiUrl + '/user/changePassword?token=' + token,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllNotOwnerUser() {
  const url = apiUrl+"/user/getAllNotOwnerUser"

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function savePassword(passwordToken) {
  const requestOptions = {
    "method": "POST",
    headers: {'content-type': 'application/json'},
    body: JSON.stringify(passwordToken)
  }
  return fetch(
    apiUrl + '/user/savePassword',
    requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  
function uploadProfileImg(formData) {
  const url = apiUrl+apiVer+"/user/uploadAvatar"

  const requestOptions = {
    method: "POST",
    headers:{Authorization: authHeader().Authorization},
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function resendOtpCode(token, email) {
  const requestOptions = {
    "method": "GET",
    headers: {'content-type': 'application/json'},
  }
  return fetch(
    apiUrl + '/prereg/resendCode?token='+token+"&email="+email,
    requestOptions
    )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function  getProfileImgById (id) {
  return apiUrl+apiVer+"/user/getAvatar/"+id;
}

function updateUser(user, id) {
  const url = apiUrl+"/user/updateUser/?id=" + id

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(user)
  }

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function handleUserStatus(id, status) {
  const url = apiUrl+"/user/activateUser/" + id + '?activated=' + status

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  }

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getUserById(id) {
  const url = apiUrl+apiVer+"/user/getUserById/" + id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllRoles() {
  const url = apiUrl+"/user/getAllRole";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getByRole(role) {
  const url = apiUrl+"/user/getByRole?role=" + role;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllUsers(page, perPage, sortType, sortValue, searchName, email, companyName) {
  const url = apiUrl+"/user/all/page/" + page + "/perPage/" + perPage + "/sort/" + sortType + "/?sortValue=" + sortValue + "&name=" + searchName + '&email=' + email + '&companyName=' + companyName;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function changeUserCompany(userId, companyId) {
  const url = apiUrl + "/user/changeCompanyUser" + "/?userId=" + userId + "&destinationCompanyId=" + companyId;
  
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };
  
  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function getAllAdmins() {
  const url = apiUrl+"/user/getAllAdmins";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
import React from 'react'
import defaultSettings from './defaultSettings';
import {gatewayConfig} from './gateway';
import { CodeSlash, Person, Upload, Building, Diagram3, GraphUp, ClipboardCheck, ReceiptCutoff, Broadcast, Coin, Database } from 'react-bootstrap-icons';
import { AppstoreAddOutlined, FileAddFilled, FormatPainterFilled, WalletFilled, DashboardFilled, DatabaseFilled, TagFilled, CalendarFilled, FundFilled, FileDoneOutlined } from '@ant-design/icons';

const { REACT_APP_ENV } = process.env;

const {
  INSIGHT_SERVICE,
  INVOICE_SERVICE,
  TAGS_SERVICE,
  SITES_SERVICE,
  FORMATS_SERVICE,
  CAMPAIGN_SERVICE,
  COMPANY_SERVICE,
  PUBLISHERS_SERVICE,
  PLATFORM_SERVICE,
  METRICS_SERVICE,
  USER_SERVICE,
  QUOTA_SERVICE,
  ADMANAGER_SERVICE,
  NOTIFICATION_SERVICE,
  CONTEXTUAL_SERVICE,
} = gatewayConfig;

if(REACT_APP_ENV){
  console.log('REACT_APP_ENV', REACT_APP_ENV)
}

const routes = [
  {
    path: '/',
    component: './pages/Overview/Overview.react',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'Dashboard',
    path: '/overview',
    component: './pages/Overview/Overview.react',
    icon: <DashboardFilled className="mx-2"/>,
    routes: [ 
      {name: 'Analytics', path: '/overview', roles: ['ROLE_PUBLISHER','ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']},
      {name: 'Contextual', path: '/contextual', roles: ['ROLE_PUBLISHER','ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']},
      {name: 'Header Bidding', path: '/prebid', roles: ['ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']},
    ],
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL'],
  },
  {
    // name: 'Dashboard',
    path: '/overview',
    component: './pages/Overview/Overview.react',
    icon: <DashboardFilled className="mx-2"/>,
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL'],
  },
  {
    // name:'Contextual',
    icon:<FileDoneOutlined className='mx-2'/>,
    path: '/contextual',
    component: './pages/Context/Overview.Page',
  },
  {
    // name:'Contextual',
    icon:<FileDoneOutlined className='mx-2'/>,
    path: '/prebid',
    component: './pages/Prebid/Prebid.Overview',
    roles: ['ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']
  },
  {
    path: '/live',
    icon: <Broadcast className="mx-2"/>,
    component: './pages/Dashboard/Dashboard.react',
    roles: ['ROLE_ADMIN', 'ROLE_OP']
  },
  {
    path: '/overview/:id',
    component: './pages/Overview/Overview.Site',
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL']
  },
  {
    path: '/contextual/:id',
    component: './pages/Context/Overview.Site',
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL']
  },
  {
    path: '/prebid/:id',
    component: './pages/Prebid/Prebid.Detail',
    roles: [ 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL']
  },
  {
    name: 'Data Import',
    path: '/insight',
    icon: <FileAddFilled className="mx-2" />,
    component: './pages/Insight/Insight.Import.react',
    routes: [ 
      { name: 'Daily', path: '/insight/import', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
      { name: 'Corrections', path: '/insight/corrections', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
      { name: 'Costs', path: '/insight/costs', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
      { name: 'Page Views', path: '/insight/pageviews', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
    ],
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/import',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Import.react',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/costs',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Cost.Import.react',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/costs/list',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Cost.List',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/corrections',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Correction',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/pageviews',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Pageviews.Import',
    roles: ['ROLE_FINANCE']
  },
  {
    name: 'Invoices',
    path: '/invoiceManager',
    icon: <WalletFilled className="mx-2" />,
    component: './pages/Invoices/Invoice.react',
    roles: ['ROLE_FINANCE', 'ROLE_PUBLISHER', 'ROLE_TL']
  },
  {
    name: 'Tag Manager',
    path: '/tagmanager',
    icon: <TagFilled className="mx-2" />,
    component: './pages/TagManager/TagManager.List.react',
    roles: ['ROLE_OP']
  },
  {
    path: '/tagmanager/new',
    icon: <CodeSlash className="mx-2" />,
    component: './pages/TagManager/TagManager.Configurator.react',
    roles: ['ROLE_OP']
  },  
  {
    path: '/tagmanager/formats',
    icon: <CodeSlash className="mx-2" />,
    component: './pages/TagManager/TagManager.FormatConfigurator',
    roles: []
  },
  {
    path: '/tagmanager/:id',
    icon: <CodeSlash className="mx-2" />,
    component: './pages/TagManager/TagManager.Configurator.react',
    roles: ['ROLE_OP' ]
  },
  {
    path: '/invoiceManager',
    icon: <ReceiptCutoff className="mx-2" />,
    component: './pages/Invoices/InvoiceNew.react',
    roles: ['ROLE_FINANCE', 'ROLE_PUBLISHER', 'ROLE_TL']
  },
  // {
  //   name: 'Campaigns',
  //   path: '/campaigns',
  //   icon: <CalendarFilled className="mx-2" />,
  //   component: './pages/Campaigns/Campaigns.react',
  //   roles: ['ROLE_FINANCE, ROLE_OP']
  // },
  // {
  //   path: '/campaigns/:id',
  //   icon: <CalendarFilled className="mx-2" />,
  //   component: './pages/Campaigns/Campaigns.Campaign',
  //   roles: ['ROLE_FINANCE, ROLE_OP']
  // },
  {
    name: 'Inventory',
    path: '/inventory',
    icon: <DatabaseFilled className="mx-2" />,
    component: './pages/Platforms/Platforms.Manager',
    routes: [ 
      { name: 'Platforms', path: '/platformManager', roles: ['ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']},
      { name: 'Publishers', path: '/companies', roles: ['ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP'] },
      { name: 'Sites', path: '/sites', roles: ['ROLE_ADMIN','ROLE_PUBLISHER', 'ROLE_OP', 'ROLE_TL'] },
      { name: 'Users', path: '/users', roles: ['ROLE_ADMIN', 'ROLE_OP'] },
    ],
    roles: ['ROLE_OP']
  },
  {
    path: '/platformManager',
    icon: <AppstoreAddOutlined />,
    component: './pages/Platforms/Platforms.Manager',
    roles: ['ROLE_FINANCE', 'ROLE_OP']
  },
  {
    path: '/companies',
    icon: <Building className="mx-2" />,
    component: './pages/Companies/CompaniesManager',
    roles: ['ROLE_FINANCE', 'ROLE_OP']
  },  
  {
    path: '/pending-approval',
    component: './pages/Signup/PendingApproval',
    roles: ['ROLE_GUEST']
  },
  {
    path: '/sites/sitemanager',
    component: './pages/Sites/Sites.SiteManager',
    roles: ['ROLE_PUBLISHER', 'ROLE_OP', 'ROLE_TL']
  },
  {
    path: '/users',
    icon: <Person className="mx-2" />,
    component: './pages/Users/UsersManager.react',
    roles: ['ROLE_ADMIN',  'ROLE_OP']
  },  
  {
    path: '/sites',
    icon: <Diagram3 className="mx-2"/>,
    component: './pages/Sites/Sites.react',
    roles: ['ROLE_PUBLISHER', 'ROLE_OP', 'ROLE_TL'],
    // routes: [
    //   { name: 'All Sites', path: '/sites', disabled: false },
    //   { name: 'Pending Sites', path: '/sites/sitemanager', icon: < ClipboardCheck className='mx-2'/> },
    // ]
  },
  {
    name: 'White Label',
    path:'/company',
    icon: <FormatPainterFilled className="mx-2" />,
    component: './pages/Companies/CompanyManager.react',
    routes: [ 
      { name: 'Company', path: '/company', roles: ['ROLE_ADMIN', 'ROLE_TL']  }
    ],
    roles: ['ROLE_ADMIN', 'ROLE_TL']
  },
  {
    path: '/company',
    icon: <Building className="mx-2" />,
    component: './pages/Companies/CompanyManager.react',
    roles: ['ROLE_PUBLISHER', 'ROLE_TL']
  },
  {
    path: '/profile',
    icon: <Person className="mx-2" />,
    component: './pages/Profile/ProfileManager.react',
    roles: ['ROLE_ADMIN', 'ROLE_OP', 'ROLE_PUBLISHER', 'ROLE_TL']
  },
  {
    path: '/businessReview',
    name: 'Business Review',
    icon: <FundFilled className="mx-2" />,
    component: './pages/BusinessReview/BusinessReview',
    roles: ['ROLE_ADMIN', 'ROLE_OP']
  },
  {
    path: '/notifications',
    component: './components/Notification/Notification.Page',
    roles: []
  },
]

export const defineConfig = {
  ...defaultSettings,
  theme: "light",
  // color: "#f44242",
  sidebar: true,
  footer: false,
  routes,
  INSIGHT_SERVICE: INSIGHT_SERVICE(REACT_APP_ENV || 'prod'),
  INVOICE_SERVICE: INVOICE_SERVICE(REACT_APP_ENV || 'prod'),
  FORMATS_SERVICE: FORMATS_SERVICE(REACT_APP_ENV || 'prod'),
  TAGS_SERVICE: TAGS_SERVICE(REACT_APP_ENV || 'prod'),
  SITES_SERVICE: SITES_SERVICE(REACT_APP_ENV || 'prod'),
  PUBLISHERS_SERVICE: PUBLISHERS_SERVICE(REACT_APP_ENV || 'prod'),
  PLATFORM_SERVICE: PLATFORM_SERVICE(REACT_APP_ENV || 'prod'),
  CAMPAIGN_SERVICE: CAMPAIGN_SERVICE(REACT_APP_ENV || 'prod'),
  METRICS_SERVICE: METRICS_SERVICE(REACT_APP_ENV || 'prod'),
  COMPANY_SERVICE: COMPANY_SERVICE(REACT_APP_ENV || 'prod'),
  USER_SERVICE: USER_SERVICE(REACT_APP_ENV || 'prod'),
  QUOTA_SERVICE: QUOTA_SERVICE(REACT_APP_ENV || 'prod'),
  ADMANAGER_SERVICE: ADMANAGER_SERVICE(REACT_APP_ENV || 'prod'),
  NOTIFICATION_SERVICE: NOTIFICATION_SERVICE(REACT_APP_ENV || 'prod'),
  CONTEXTUAL_SERVICE: CONTEXTUAL_SERVICE(REACT_APP_ENV || 'prod')
}



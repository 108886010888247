import { authHeader } from "../helpers";
import { defineConfig } from '../config';
import { utilsService } from './utils';
import FileSaver from "file-saver";

export const invoiceService = {
  getAllInvoices,
  uploadInvoice,
  payInvoice,
  getPDFFile,
  getRecap,
  getAllCompanyInvoices,
  getInvoiceDetail,
  updateInvoiceDetail,
  previewInvoicePDF,
  sendInvoiceDetail,
  downloadInvoicesInvites,
  getAllInvoiceIdsByDate,
  recalculateInvoiceInvitations,
  deleteInvoiceById,
  deleteAllInvites,
  createCompanyInvoice
};

const { handleResponse, handleResponseBlob } = utilsService;

const { apiUrl, apiVer } = defineConfig.INVOICE_SERVICE

function getRecap(id, endDate, startDate) {
  const url = apiUrl + apiVer + '/invoiceInvites/getRecapInvoice/?companyId=' + id + "&dateEnd=" + endDate + "&dateStart=" + startDate

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllInvoices(page, perPage, companyId, dateEnd, dateStart, siteName, sortType, sortValue, status) {
  const url = apiUrl + apiVer + "/invoiceInvites/all/page/" + page + "/perPage/" + perPage + "/sort/" +
    sortType + '?companyId=' + companyId + '&dateEnd=' + dateEnd + '&dateStart=' + dateStart +
    '&siteName=' + siteName + '&sortValue=' + sortValue + '&status=' + status;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function uploadInvoice(formData, id) {
  const url = apiUrl + apiVer + "/invoiceInvites/uploadInvoicePdf/?invitesInvoiceId=" + id

  const requestOptions = {
    method: "POST",
    headers: { Authorization: authHeader().Authorization },
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPDFFile(id) {
  const url = apiUrl + apiVer + "/invoiceInvites/getInvoicePdf/" + id

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponseBlob)
    .then(res => {
      return res;
    });
}

function previewInvoicePDF(invoice, companyInvoiceId) {
  const url = apiUrl + apiVer + "/companyInvoices/pdf/preview/?companyInvoiceId=" + companyInvoiceId;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/JSON"),
    body: JSON.stringify(invoice)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponseBlob)
    .then(res => {
      return res;
    });
}

function payInvoice(id) {
  const url = apiUrl + apiVer + "/invoiceInvites/setPayed/?invoiceId=" + id

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllCompanyInvoices(page, perPage, sortType, companyName, dateEnd, dateStart, sortValue, status) {
  const url = apiUrl + apiVer + "/companyInvoices/all/page/" + page + "/perPage/" + perPage + "/sort/" +
    sortType + '?companyName=' + companyName + '&dateEnd=' + dateEnd + '&dateStart=' + dateStart +
    '&sortValue=' + sortValue + '&status=' + status;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getInvoiceDetail(id) {
  const url = apiUrl + apiVer + "/companyInvoices/getInvoice/" + id

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateInvoiceDetail(id, body) {
  const url = apiUrl + apiVer + "/companyInvoices/saveInvoice/?companyInvoiceId=" + id

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function sendInvoiceDetail(body) {
  const url = apiUrl + apiVer + "/companyInvoices/sendInvoiceInvites"

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function recalculateInvoiceInvitations(dateEnd, dateStart) {
  const url = apiUrl + apiVer + '/companyInvoices/regenerateInvoiceInvitations'+ '?dateEnd=' + dateEnd + '&dateStart=' + dateStart

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getFileFromUrl(url, fileName = null, body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  if (fileName === null) {
    fileName = url.split('/')
    fileName = fileName[fileName.length - 1]
  }

  return fetch(url, requestOptions)
    .then(response => {
      console.log("scarico....", url)
      return response.blob()
    })
    .then(function (blob) {
      FileSaver.saveAs(blob, fileName)
      return true;
    })
}

function downloadInvoicesInvites(body, month) {
  const url = apiUrl + apiVer + "/companyInvoices/downloadInvoiceInvites"

  const fileName = 'invoice_invites_' + month + '.zip';
  return getFileFromUrl(url, fileName, body)
}

function getAllInvoiceIdsByDate(endDate, startDate) {
  const url = apiUrl + apiVer + "/companyInvoices/getAllCompanyInvoiceIdsByDateRange?endDate=" + endDate + "&startDate=" + startDate

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createCompanyInvoice(body, companyId, startDate, endDate) {
  const url = apiUrl + apiVer + "/companyInvoices/createCompanyInvoice/?companyId=" + companyId + "&dateEnd=" + endDate + "&dateStart=" + startDate;
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  }

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteInvoiceById(id) {
  const url = apiUrl + apiVer + "/companyInvoices/deleteCompanyInvoice?companyInvoiceId=" + id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteAllInvites(startDate, endDate) {
  const url = apiUrl + apiVer + "/companyInvoices/deleteInvoiceInvitations/?dateEnd=" + endDate + "&dateStart=" + startDate;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
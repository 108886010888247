import {userService} from '../services';

export const utilsService = {
    handleResponse,
    handleResponseBlob
};


function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        switch(response.status){
          case 401:
             // auto logout if 401 response returned from api
            window.location.reload("/logout?status=unauthorized");
            break;
          case 409:
            return Promise.resolve({...data, statusCode: response.status})
          case 404:
            if(response.statusText){
              return Promise.reject(response.error);
            }
            return Promise.resolve({...data, statusCode: response.status})
          // case 500:
          //   console.log(response)
          //   return Promise.reject(response.error);
          default:
        }
        
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
  
      return data;
    });
}

function handleResponseBlob(response) {
    return response.blob().then(blob => {
        return blob;
      }
    );
}
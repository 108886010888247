import {defineConfig} from '../config'
import { authHeader } from '../helpers';
import { utilsService } from './utils'
const {handleResponse} = utilsService

export const formatsService = {
    getApiPath,
    getAllFormats,
    createFormat,
    deleteFormat,
    getFormatById,
    updateFormat
};

const {apiUrl, apiVer} = defineConfig.FORMATS_SERVICE

function getApiPath(){
  return (apiUrl+apiVer)
}

function getAllFormats() {
  const url = apiUrl+apiVer+"/format/findAll";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getFormatById(id) {
  const url = apiUrl + apiVer +"/format/"+ id;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateFormat(id, formatTemplate) {
  const url = apiUrl+apiVer+"/format/update/" + id ;
  
  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(formatTemplate)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createFormat(formatTemplate) {
  const url = apiUrl+apiVer+"/format/create";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(formatTemplate)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteFormat(formatId) {
  const url = apiUrl+apiVer+"/format/delete/" + formatId;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { userService } from "../../services";

export const PrivateRoute = ({ component: Component, isAllowed, ...rest }) => {
  useEffect(() => {
    userService.checkProfile();
  }, []);

  // if (!localStorage.getItem('user') || !localStorage.getItem('type')) {
  //   return <Navigate to="/login" />;
  // }

  // if (!isAllowed) {
  //   return <Navigate to="/403" />;
  // }

  return <Component {...rest} />;
};